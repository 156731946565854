@keyframes boxJump {
  0%,
  100% {
    transform: translateY(-0.42vw);
    background: #febe41;
  }
  20%,
  80% {
    transform: translateY(0);
    background: #212022;
  }
}

@media (max-width: 600px) {
  @keyframes boxJump {
    0%,
    100% {
      transform: translateY(-8px);
      background: #febe41;
    }
    20%,
    80% {
      transform: translateY(0);
      background: #212022;
    }
  }
}
