body {
  margin: 0;
  font-family: EuclidSquare;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171717;
  color: #514b45;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent; /* 移动端点击背景色 */
  -webkit-appearance: none;
}

html {
  font-size: 0.73vw;
  /* overflow: scroll; */
  overflow-x: hidden;
  overflow-y: auto;
}

/* 隐藏滚动条的样式 */
body::-webkit-scrollbar {
  width: 0;
  background-color: #febe41;
  border-radius: 0.25rem;
}

/* 隐藏滚动条拖动条的样式 */
body::-webkit-scrollbar-thumb {
  background-color: #443b2d;
  border-radius: 0.25rem;
}

/* 隐藏滚动条角落的样式 */
body::-webkit-scrollbar-corner {
  background-color: #febe41;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

@media (max-width: 600px) {
  html {
    font-size: 16px;
  }
}
