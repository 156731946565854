.box {
  position: relative;
}

.size {
  width: 100%;
  height: auto;
  transition-property: opacity;
  transition-duration: 0.5s;
}

.pre_box {
  position: absolute;
  top: 0;
  height: 100%;
  filter: blur(4px);
  overflow: hidden;
}

.preBreath {
  animation: breath 1s linear 1s infinite alternate;
}

@keyframes breath {
  0% {
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    opacity: 0.5;
    filter: blur(2px);
  }
}
